import apiConfig from '../../../config';
import serviceCall from '../../../utils/api';
import { serviceCallBasedOnMethod } from '../../helperFunctions';
import Creators, {
  loadProductTourSkipDonePages,
  toggleUpgradeModal,
  setUserDetails,
  toggleSidebar,
} from './actions';

const {
  setUploadFile,
  updateUploadFile,
  removeUploadFile,
  toggleEditorView,
  setTeam,
  updateUserTeam,
} = Creators;

const getUserDetails = data => dispatch => dispatch(setUserDetails(data));

const addUploadFile = data => dispatch => dispatch(setUploadFile(data));

const updateUploadData = (id, data) => {
  return dispatch => {
    dispatch(updateUploadFile(id, data));
  };
};

const removeUploadData = data => {
  return dispatch => {
    dispatch(removeUploadFile(data));
  };
};
const saveTeam = data => dispatch => dispatch(setTeam(data)); // to set team
const updateTeam = data => dispatch => dispatch(updateUserTeam(data)); // to set team
const sidebarToggle = data => dispatch => dispatch(toggleSidebar(data));
const upgradeModalAction = data => dispatch =>
  dispatch(toggleUpgradeModal(data)); // to invoke upgrade plan modal
const setEditorView = data => dispatch => dispatch(toggleEditorView(data)); // to change editor view flag
const getCloudTime = request =>
  serviceCall.get(`${apiConfig.project}/requested/resource/`, request);
const loadRequestedCloudSytemDetails = data => dispatch =>
  dispatch(Creators.loadRequestedCloudSytemDetails(data));
export const productTourService = (request, method = 'get') =>
  serviceCallBasedOnMethod(`${apiConfig.auth}/product/tour/`, request, method);
const setProductTourSkipDone = data => dispatch =>
  dispatch(loadProductTourSkipDonePages(data)); // to set team

export default {
  saveTeam,
  getUserDetails,
  addUploadFile,
  updateUploadData,
  removeUploadData,
  sidebarToggle,
  upgradeModalAction,
  getCloudTime,
  setEditorView,
  loadRequestedCloudSytemDetails,
  updateTeam,
  productTourService,
  setProductTourSkipDone,
};
