import styled from 'styled-components';
import { whiteAlpha } from './utils';
import { colors, fs } from './variables';

const Wrapper = styled.div`
  /* height: 100vh; */
  display: flex;
  font-size: 3.2rem;
  align-items: center;
  color: ${colors.white};
  flex-direction: column;
  justify-content: center;
  color: ${whiteAlpha(0.8)};
  height: calc(100vh - 80px);
`;

const ImageWrapper = styled.div`
  width: 25rem;
  img {
    width: 100%;
  }
`;

const Header = styled.p`
  font-size: 1.8rem;
  padding: 0;
  margin: 1.5rem 0 4rem;
`;

const SubHeader = styled.p`
  font-size: ${fs.sm};
  padding: 0;
  margin-top: 3.7rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  .MuiButton-label {
    font-size: ${fs.sm};
  }
  a {
    display: block;
    font-weight: 400;
    min-width: 16rem;
    font-size: ${fs.sm};
    margin-right: 2rem;
    padding: 1.5rem 6rem;
    border-radius: 3.2rem;
    text-decoration: none;
    color: ${colors.white};
    text-transform: uppercase;
    background: ${colors.blue.primary};
  }
`;

export { Wrapper, ImageWrapper, Header, SubHeader, ButtonWrapper };
