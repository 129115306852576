import React from 'react';
import styled from 'styled-components';
import { LinkedCamera, CreateNewFolderOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import apiConfig from '../../../../config';
import { toggleSidebar } from '../../../main-wrapper/duck/actions';
import sidebarTypes from '../../../components/Sidebar/constants';
import { CAMERA_TO_EDIT_SESSION_STEPS } from '../../constants';

// Styles
import { AnchorLinkStyles, Text } from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';
import { colors } from '../../../styles/variables';

const NoResults = `${apiConfig.assets}/c2e.svg`;

const Browse = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  .image-wrapper {
    width: 30rem;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .upload-text {
    font-size: 2rem;
    color: ${whiteAlpha(0.8)};
    padding: 0;
    margin-top: 5rem;
  }

  .upload-block {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3rem;
  }
`;

const UploadSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.1rem solid ${colors.blue.primary};
    border-radius: 50%;
    background: transparent;
    padding: 0.5rem;
    .MuiSvgIcon-root {
      fill: ${colors.blue.primary};
      width: 1.4em;
      height: 1.4em;
    }
  }
  .upload-type {
    font-size: 1.4rem;
    color: ${colors.blue.primary};
    padding: 0 0 0 1rem;
  }
  &:hover {
    .icon-wrapper {
      background: ${colors.blue.primary};
      .MuiSvgIcon-root {
        fill: ${colors.white};
      }
    }
  }
`;

const CameraToEditEmpty = ({ onCreateFolder }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      toggleSidebar({
        show: true,
        type: sidebarTypes.cameraToEdit,
        title: 'Camera to Edit',
        componentProps: {
          view: CAMERA_TO_EDIT_SESSION_STEPS.new,
        },
      })
    );
  };

  return (
    <Browse>
      <div className='image-wrapper'>
        <img src={NoResults} alt='Start C2E Session' />
      </div>

      <p className='upload-text'>Upload assets from the camera to Postudio.</p>
      <Text padding='0' marginTop='1rem' marginBottom='5rem'>
        Learn how{' '}
        <span>
          <span>
            <AnchorLinkStyles
              href='https://postudio.tawk.help/article/camera-edit'
              target='_blank'
              rel='noreferrer'>
              Camera to Edit
            </AnchorLinkStyles>
          </span>
        </span>{' '}
        works.
      </Text>

      <div className='upload-block'>
        <UploadSection role='button' onClick={onClick}>
          <div className='icon-wrapper'>
            <LinkedCamera />
          </div>
          <p className='upload-type'>Start C2E Session</p>
        </UploadSection>

        <UploadSection role='button' onClick={onCreateFolder}>
          <div className='icon-wrapper'>
            <CreateNewFolderOutlined />
          </div>
          <p className='upload-type'>Create Folder</p>
        </UploadSection>
      </div>
    </Browse>
  );
};

CameraToEditEmpty.propTypes = {
  onCreateFolder: PropTypes.func,
};

export default CameraToEditEmpty;
