import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { Send } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';

import {
  db,
  PROJECT_CHAT_REF,
  PROJECT_CHAT_COUNT_REF,
} from '../../../Firebase';
import { useForm } from '../../../components/FormInputs';
import DateTime from '../../../components/dateTime';
import apiConfig from '../../../../config';
import sidebarTypes from '../../../components/Sidebar/constants';
import Tooltip from '../../../components/tooltip';
import InfiniteScroll from '../../../components/infiniteScroll';
import MentionInputField from '../../../components/FormInputs/Mention';

import { getProjectChatPreviousMessagesService } from '../../duck/operations';

// styles
import { Body, Footer } from '../../../styles/sidebar';
import {
  Flex,
  NoDetailsFound,
  Text,
  CustomAvatarStyles,
  AvatarUsernameStyles,
} from '../../../styles/common';
import { whiteAlpha } from '../../../styles/utils';
import { colors, fs } from '../../../styles/variables';

const TextBoxWithSubmit = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  background-color: ${whiteAlpha(0.15)};
  margin-left: 1rem;

  .mentions--singleLine {
    width: 21.6rem;
  }

  .mentions__input,
  .mentions__highlighter {
    padding: 1.75rem 0 1.75rem 1.5rem;
  }
`;

const ProjectHelpButtonStyles = styled(IconButton)`
  &.MuiIconButton-root {
    border-radius: 1rem;
    padding: 1.45rem;
    background-color: ${whiteAlpha(0.15)};

    &:hover {
      background-color: ${whiteAlpha(0.15)};
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: ${whiteAlpha(0.8)};
  }
`;

const SubmitButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 1rem 1.5rem;
    padding: 0.95rem;
    background-color: ${colors.blue.shadeTwo};

    &:hover {
      background-color: #2c86ff;
      .MuiSvgIcon-root {
        color: ${colors.white};
      }
    }
  }

  .MuiSvgIcon-root {
    width: 1.4rem;
    height: 1.4rem;
    color: ${colors.blue.navy};
  }
`;

const MessageDateLabelStyles = styled(Text)`
  text-align: center;
  margin: 0;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  font-size: 1rem;
  display: inline-block;
  background-color: ${whiteAlpha(0.15)};
`;

const MessageBodyStyles = styled.div`
  margin-bottom: 1.3rem;
`;

const MessageStyle = styled.div`
  padding: 0;

  p {
    padding: 0;
  }

  #user {
    font-weight: 500;
  }

  .user-timestamp {
    flex: 1 1 0%;
  }

  .message-container {
    position: relative;

    &:hover {
      #time {
        display: block;
      }
    }

    .message {
      font-size: ${fs.sm};
      color: ${whiteAlpha(0.9)};
      padding: 0.2rem 0.2rem 0.2rem 0;
      overflow-wrap: anywhere;
    }

    #time {
      display: none;
      padding: 0.2rem 0.4rem;
      color: ${colors.white};
      font-size: 1rem;
      text-align: right;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0.2rem;
      background-color: ${colors.black.default};
      backdrop-filter: blur(13.5914px);
    }
  }
`;

const INITIAL_STATE = {
  message: {
    value: '',
  },
  plainTextMessage: {
    value: '',
  },
};

const ProjectChat = props => {
  const {
    toggleSidebar,
    toggleToast,
    match: { params },
  } = props;

  const [loadingMessages, setLoadingMessages] = useState(true);
  const [previousMessagesLoading, setPreviousMessagesLoading] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [scrollingTop, setScrollingTop] = useState(false);
  const [totalEntries, setTotalEntries] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [messages, setMessages] = useState([]);
  const [usersMentions, setUserMentions] = useState([]);

  const userDetails = useSelector(store => store.common?.userDetails);

  const BASE_REF = PROJECT_CHAT_REF + `/${params?.project_db_id}`;
  const TOTAL_PROJECT_CHAT_COUNT_REF =
    PROJECT_CHAT_COUNT_REF + `/${params?.project_db_id}`;

  const messagesEndRef = useRef(null);

  const getPreviousMessages = async page => {
    const response = await getProjectChatPreviousMessagesService({
      project_db_id: params?.project_db_id,
      entries_per_page: 20,
      page_no: page ?? 1,
    });
    setTotalEntries(response?.data?.data?.total_entries);
    return response?.data?.data?.messages ?? [];
  };

  // to scroll bottom
  useEffect(() => {
    if (messages && scrollToBottom) {
      // eslint-disable-next-line no-unused-expressions
      messagesEndRef?.current?.scrollIntoView();
      setScrollingTop(false);
    }
  }, [messages, scrollToBottom, loadingMessages]);

  useEffect(() => {
    let messagesFromFirebase = [];
    (async () => {
      const data = await getPreviousMessages();
      db.ref(BASE_REF).on('value', snapshot => {
        const values = snapshot.val();
        messagesFromFirebase = values ? Object.values(values) : [];
        setLoadingMessages(false);
        const messagesWithLabel =
          messagesFromFirebase?.length > 0
            ? [
                {
                  date: 'Today',
                  label: 'Today',
                  messages: [...messagesFromFirebase],
                },
              ]
            : [];
        setMessages([...data, ...messagesWithLabel]);
      });
    })();

    return () => {
      // to update last_seen_count of user
      db.ref(TOTAL_PROJECT_CHAT_COUNT_REF).transaction(snapshot => {
        const updated = snapshot;
        if (updated) {
          updated[userDetails?.user_id] = {
            last_seen_count: updated.chats_count,
          };
        }
        return updated;
      });
      db.ref(PROJECT_CHAT_REF).off();
    };
  }, []);

  useEffect(() => {
    if (scrollingTop) {
      const total =
        messages &&
        messages.reduce((acc, curr) => acc + curr.messages.length, 0);

      if (total < totalEntries && scrollingTop) {
        setPreviousMessagesLoading(true);
        (async () => {
          const data = await getPreviousMessages(pageNo);
          setPageNo(prev => prev + 1);
          setPreviousMessagesLoading(false);
          setMessages(prev => [...data, ...prev]);
        })();
      }
    }
  }, [scrollingTop, pageNo, totalEntries, messages]);

  const { onSubmit, formValues, setFormValues } = useForm(
    INITIAL_STATE,
    async () => {
      if (formValues?.plainTextMessage?.value) {
        const MESSAGE_JSON = {
          text: formValues?.plainTextMessage.value,
          timestamp: new Date().getTime(),
          ...(usersMentions?.length > 0 && {
            mention: {
              user_ids: usersMentions.map(el => el.id),
            },
          }),
        };

        const COMMON = {
          project_id: params?.project_db_id,
          image_url: `${apiConfig.assets}/chat-icon.svg`,
          created_on: new Date().getTime(),
          user: {
            user_id: userDetails?.user_id,
            user_name: userDetails?.name,
            user_badge_color: userDetails?.user_badge_color,
          },
        };

        const PARENT_MESSAGES = messages[messages.length - 1] ?? null;
        const LAST_USER_MESSAGES =
          PARENT_MESSAGES?.messages[PARENT_MESSAGES?.messages.length - 1] ??
          null;

        const updates = {};
        let updatedMessages = [];
        let updatedKey = null;

        if (
          LAST_USER_MESSAGES?.user?.user_id === userDetails?.user_id &&
          PARENT_MESSAGES.label === 'Today'
        ) {
          updatedKey = LAST_USER_MESSAGES.id;
          updatedMessages = [...LAST_USER_MESSAGES.messages, MESSAGE_JSON];
        } else {
          const { key } = db.ref().child(BASE_REF).push();
          updatedKey = key;
          updatedMessages.push(MESSAGE_JSON);
        }

        COMMON.id = updatedKey;
        COMMON.messages = updatedMessages;

        updates[BASE_REF + '/' + updatedKey] = COMMON;

        db.ref()
          .update(updates)
          .then(() => {
            // transaction for concurrent updates
            db.ref(TOTAL_PROJECT_CHAT_COUNT_REF).transaction(snapshot => {
              let updated = snapshot;
              if (updated) {
                updated.chats_count += 1;
              } else {
                updated = { chats_count: 1 };
              }
              return updated;
            });
          })
          .catch(() => {
            toggleToast({
              message: {
                messageHead: 'Error',
                messageBody: 'Something went wrong',
                variant: 'error',
              },
            });
          });

        // reset text field
        setFormValues(prev => ({
          ...prev,
          plainTextMessage: {
            ...prev.plainTextMessage,
            value: '',
          },
          message: {
            ...prev.message,
            value: '',
          },
        }));
        setUserMentions([]); // reset user mention
      }
      setScrollToBottom(true);
    }
  );

  const formatMessage = message => {
    const converted = message?.toString();
    if (
      converted.includes('https://') ||
      converted.includes('http://') ||
      converted.includes('@')
    ) {
      return converted?.split(' ')?.map(str => {
        if (str.includes('https://') || str?.includes('http://')) {
          return (
            <span>
              <a
                href={str}
                target='_blank'
                rel='noopener noreferrer'
                style={{ color: colors?.blue.shadeTwo }}
              >
                {`${str} `}
              </a>
            </span>
          );
        }
        if (str?.includes('@')) {
          return <span style={{ color: colors?.blue.shadeTwo }}>{str} </span>;
        }
        return <span>{str} </span>;
      });
    }

    return converted;
  };

  const toggleProjectHelp = () => {
    toggleSidebar({
      type: sidebarTypes.projectChat,
      show: true,
      title: 'Project Chat',
    });
  };

  const onProjectHelp = () => {
    toggleSidebar({
      goBack: toggleProjectHelp,
      type: sidebarTypes.projectHelp,
      show: true,
      title: 'Project Help',
    });
  };

  const onInputChange = (event, newValue, newPlainTextValue, mentions) => {
    setUserMentions(mentions);
    setFormValues(prev => ({
      ...prev,
      message: {
        ...prev.message,
        value: newValue,
      },
      plainTextMessage: {
        ...prev.plainTextMessage,
        value: newPlainTextValue,
      },
    }));
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Body>
          {loadingMessages ? (
            <Text aria-label='fetching-messages' className='text-center p-0'>
              Fetching messages...
            </Text>
          ) : (
            <>
              {messages?.length ? (
                <InfiniteScroll
                  scrollDirection='reverse'
                  isLoading={previousMessagesLoading}
                  loadAssets={() => {
                    setScrollingTop(true);
                    setScrollToBottom(false);
                  }}
                >
                  <>
                    {previousMessagesLoading && (
                      <Text
                        aria-label='fetching-messages'
                        className='text-center p-0'
                        marginBottom='1.3rem'
                      >
                        Fetching pervious messages...
                      </Text>
                    )}

                    {messages?.map((el, i) => {
                      return (
                        <div aria-label='parent' key={i}>
                          <div
                            style={{
                              textAlign: 'center',
                              marginTop: '2.6rem',
                              marginBottom: '1.3rem',
                            }}
                          >
                            {el?.label && (
                              <MessageDateLabelStyles>
                                {el?.label}
                              </MessageDateLabelStyles>
                            )}
                          </div>
                          {el?.messages.map(inner => {
                            const {
                              user: { user_name, user_id, user_badge_color },
                            } = inner;
                            const initials = user_name.split(' '); // get initials
                            const isSelf = user_id === userDetails?.user_id;

                            return (
                              <>
                                <MessageBodyStyles key={`${user_id}_${i}`}>
                                  <MessageStyle isSelf={isSelf}>
                                    <Flex className='items-start'>
                                      <CustomAvatarStyles
                                        color={user_badge_color}
                                      >
                                        {initials[0].charAt(0) +
                                          initials[1].charAt(0)}
                                      </CustomAvatarStyles>

                                      <div
                                        aria-label='message-user-timestamp'
                                        className='ml-2 user-timestamp'
                                      >
                                        <AvatarUsernameStyles id='user'>
                                          {user_name}
                                        </AvatarUsernameStyles>

                                        {inner?.messages?.map((m, index) => {
                                          const nestedDate = new Date(
                                            m?.timestamp
                                          );
                                          return (
                                            <>
                                              <div
                                                className='message-container'
                                                key={`${m?.timestamp}_id_${index}`}
                                              >
                                                <Text className='message'>
                                                  {formatMessage(m?.text)}
                                                </Text>
                                                <Text id='time'>
                                                  {DateTime.toTimeString(
                                                    nestedDate,
                                                    'HH:MM AP'
                                                  )}
                                                </Text>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                    </Flex>
                                  </MessageStyle>
                                </MessageBodyStyles>
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </>
                  <div ref={messagesEndRef} />
                </InfiniteScroll>
              ) : (
                <NoDetailsFound>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='60'
                    height='56'
                    viewBox='0 0 60 56'
                  >
                    <g fill='#FFF' fillOpacity='.4'>
                      <path d='M10 23.465h13c.553 0 1-.448 1-1s-.447-1-1-1H10c-.553 0-1 .448-1 1s.447 1 1 1zM36 27.465H10c-.553 0-1 .448-1 1s.447 1 1 1h26c.553 0 1-.448 1-1s-.447-1-1-1zM36 33.465H10c-.553 0-1 .448-1 1s.447 1 1 1h26c.553 0 1-.448 1-1s-.447-1-1-1z' />

                      <path d='M54.072.535L19.93.465c-3.27 0-5.93 2.66-5.93 5.93v5.124l-8.07.017c-3.27 0-5.93 2.66-5.93 5.93v21.141c0 3.27 2.66 5.929 5.93 5.929H12v10c0 .413.254.784.64.933.117.045.239.067.36.067.276 0 .547-.115.74-.327l9.704-10.675 16.626-.068c3.27 0 5.93-2.66 5.93-5.929v-.113l5.26 5.786c.193.212.464.327.74.327.121 0 .243-.022.36-.067.386-.149.64-.52.64-.933v-10h1.07c3.27 0 5.93-2.66 5.93-5.929V6.465c0-3.269-2.659-5.929-5.928-5.93zM44 38.536c0 2.167-1.763 3.929-3.934 3.929l-17.07.07c-.28.001-.548.12-.736.327L14 51.949v-8.414c0-.552-.447-1-1-1H5.93c-2.167 0-3.93-1.763-3.93-3.929V17.465c0-2.167 1.763-3.93 3.932-3.93L15 13.516h.002l25.068-.052c2.167 0 3.93 1.763 3.93 3.93v21.142zm14-10.93c0 2.167-1.763 3.929-3.93 3.929H52c-.553 0-1 .448-1 1v8.414l-5-5.5V17.395c0-3.27-2.66-5.93-5.932-5.93L16 11.514v-5.12c0-2.167 1.763-3.93 3.928-3.93l34.141.07h.002c2.167 0 3.93 1.763 3.93 3.93v21.142H58z' />
                    </g>
                  </svg>

                  <div className='content'>
                    <div className='head'>It’s quiet in here...</div>
                    <div className='sub-head'>Let’s start a conversation!</div>
                  </div>
                </NoDetailsFound>
              )}
            </>
          )}
        </Body>
        <Footer className='items-center'>
          {!loadingMessages && (
            <>
              <Tooltip title='Project Help' hide placement='top' arrow>
                <ProjectHelpButtonStyles
                  type='button'
                  onClick={onProjectHelp}
                  aria-controls='customized-menu'
                  aria-haspopup='true'
                  variant='contained'
                >
                  <svg
                    fill='currentColor'
                    strokeWidth='0'
                    role='img'
                    viewBox='0 0 24 24'
                    height='1em'
                    width='1em'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path d='M22.2819 9.8211a5.9847 5.9847 0 0 0-.5157-4.9108 6.0462 6.0462 0 0 0-6.5098-2.9A6.0651 6.0651 0 0 0 4.9807 4.1818a5.9847 5.9847 0 0 0-3.9977 2.9 6.0462 6.0462 0 0 0 .7427 7.0966 5.98 5.98 0 0 0 .511 4.9107 6.051 6.051 0 0 0 6.5146 2.9001A5.9847 5.9847 0 0 0 13.2599 24a6.0557 6.0557 0 0 0 5.7718-4.2058 5.9894 5.9894 0 0 0 3.9977-2.9001 6.0557 6.0557 0 0 0-.7475-7.0729zm-9.022 12.6081a4.4755 4.4755 0 0 1-2.8764-1.0408l.1419-.0804 4.7783-2.7582a.7948.7948 0 0 0 .3927-.6813v-6.7369l2.02 1.1686a.071.071 0 0 1 .038.052v5.5826a4.504 4.504 0 0 1-4.4945 4.4944zm-9.6607-4.1254a4.4708 4.4708 0 0 1-.5346-3.0137l.142.0852 4.783 2.7582a.7712.7712 0 0 0 .7806 0l5.8428-3.3685v2.3324a.0804.0804 0 0 1-.0332.0615L9.74 19.9502a4.4992 4.4992 0 0 1-6.1408-1.6464zM2.3408 7.8956a4.485 4.485 0 0 1 2.3655-1.9728V11.6a.7664.7664 0 0 0 .3879.6765l5.8144 3.3543-2.0201 1.1685a.0757.0757 0 0 1-.071 0l-4.8303-2.7865A4.504 4.504 0 0 1 2.3408 7.872zm16.5963 3.8558L13.1038 8.364 15.1192 7.2a.0757.0757 0 0 1 .071 0l4.8303 2.7913a4.4944 4.4944 0 0 1-.6765 8.1042v-5.6772a.79.79 0 0 0-.407-.667zm2.0107-3.0231l-.142-.0852-4.7735-2.7818a.7759.7759 0 0 0-.7854 0L9.409 9.2297V6.8974a.0662.0662 0 0 1 .0284-.0615l4.8303-2.7866a4.4992 4.4992 0 0 1 6.6802 4.66zM8.3065 12.863l-2.02-1.1638a.0804.0804 0 0 1-.038-.0567V6.0742a4.4992 4.4992 0 0 1 7.3757-3.4537l-.142.0805L8.704 5.459a.7948.7948 0 0 0-.3927.6813zm1.0976-2.3654l2.602-1.4998 2.6069 1.4998v2.9994l-2.5974 1.4997-2.6067-1.4997Z' />
                  </svg>
                </ProjectHelpButtonStyles>
              </Tooltip>

              <TextBoxWithSubmit>
                <MentionInputField
                  // autoFocus
                  searchPayload={{ project_db_id: params?.project_db_id }}
                  onChange={onInputChange}
                  placeholder='Type your message'
                  value={formValues.message.value}
                />
                <SubmitButton type='submit'>
                  <Send />
                </SubmitButton>
              </TextBoxWithSubmit>
            </>
          )}
        </Footer>
      </form>
    </>
  );
};

ProjectChat.propTypes = {
  match: PropTypes.instanceOf(Object),
  toggleToast: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

export default ProjectChat;
