/* eslint-disable indent */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

// Components
import Plugins from './Plugins';
import Softwares from './Softwares';
import SystemTypes from './SystemTypes';
import Hardware from './Hardware';
import Duration from './Duration';
import NotAvailable from './NotAvailable';
import Available from './Available';
import Preparing from './Preparing';
import Ready from './Ready';
import InQueue from './InQueue';
import NextSteps from './NextSteps';

// Constants
import { CLOUD_SCREENS } from './constants';

const CloudAccess = props => {
  const {
    closeSidebar,
    toggleToast,
    getSystemResources,
    isResourceAvailable,
    match,
    location,
    systemSession,
    getSystems,
    selectedTask,
    cloudAccessDetails,
    extendSession,
    toggleModal,
    screenFromProject,
    createCancelQueueRequest,
    toggleUpgradeModal,
    getNiceDcvSdkDownloadLink,
  } = props;

  const [type, setType] = useState({}); // selected type i.e image, video etc
  const [config, setConfig] = useState({}); // selected configs
  const [screen, setScreen] = useState(screenFromProject || CLOUD_SCREENS.type);
  const [hardwares, setHardwares] = useState([]);
  const [selectedSoftware, selectSoftware] = useState(null);
  const [selectedHardware, selectHardware] = useState(null);
  const [selectedPlugin, selectPlugin] = useState([]);
  const [isPluginSkipped, selectSkipPlugin] = useState(false);

  // to download app
  const downloadNiceDcvSdk = item => {
    getNiceDcvSdkDownloadLink({
      os: item?.key,
      key: 'ps_connectors',
    })
      .then(res => {
        const { data } = res;
        if (data && data.response) {
          window.open(data.data, '_blank');
        } else {
          throw new Error(data.errormessage);
        }
      })
      .catch(err => {
        toggleToast({
          message: {
            messageHead: 'Error',
            messageBody: err?.message,
            variant: 'error',
          },
        });
      });
  };

  const renderComponents = () => {
    let component = null;

    const commonProps = {
      closeSidebar,
      config,
      setConfig,
      type,
      goTo: to => setScreen(to), // go to direct specific screen
      match,
      location,
      cloudAccessDetails,
      task: selectedTask,
      toggleUpgradeModal,
      toggleToast,
      selectedSoftware,
      selectedPlugin,
      selectedHardware,
    };

    switch (screen) {
      case CLOUD_SCREENS.type:
        component = (
          <SystemTypes
            {...commonProps}
            setType={setType}
            getSystemResources={getSystemResources}
          />
        );
        break;
      case CLOUD_SCREENS.softwares:
        component = (
          <Softwares
            {...commonProps}
            selectSoftware={selectSoftware}
            selectPlugin={selectPlugin}
            selectSkipPlugin={selectSkipPlugin}
            setHardwares={setHardwares}
          />
        );
        break;

      case CLOUD_SCREENS.hardware:
        component = (
          <Hardware
            {...commonProps}
            selectHardware={selectHardware}
            getSystems={getSystems}
            isPluginSkipped={isPluginSkipped}
            hardwares={hardwares}
            setHardwares={setHardwares}
          />
        );
        break;

      case CLOUD_SCREENS.plugins:
        component = (
          <Plugins
            {...commonProps}
            selectPlugin={selectPlugin}
            isResourceAvailable={isResourceAvailable}
            getSystems={getSystems}
            setHardwares={setHardwares}
          />
        );
        break;

      case CLOUD_SCREENS.duration:
        component = (
          <Duration
            {...commonProps}
            extendSession={extendSession}
            isResourceAvailable={isResourceAvailable}
          />
        );
        break;

      case CLOUD_SCREENS.available:
        component = (
          <Available {...commonProps} systemSession={systemSession} />
        );
        break;

      case CLOUD_SCREENS.notAvailable:
        component = (
          <NotAvailable
            {...commonProps}
            createCancelQueueRequest={createCancelQueueRequest}
          />
        );
        break;

      case CLOUD_SCREENS.inQueue:
        component = (
          <InQueue
            {...commonProps}
            createCancelQueueRequest={createCancelQueueRequest}
          />
        );
        break;

      case CLOUD_SCREENS.preparing:
        component = <Preparing {...commonProps} />;
        break;

      case CLOUD_SCREENS.ready:
        component = (
          <Ready
            {...commonProps}
            toggleModal={toggleModal}
            downloadNiceDcvSdk={downloadNiceDcvSdk}
          />
        );
        break;

      case CLOUD_SCREENS.nextSteps:
        component = (
          <NextSteps {...commonProps} downloadNiceDcvSdk={downloadNiceDcvSdk} />
        );
        break;

      default:
        break;
    }

    return component;
  };

  return renderComponents();
};

CloudAccess.propTypes = {
  getSystemResources: PropTypes.func,
  isResourceAvailable: PropTypes.func,
  getSystems: PropTypes.func,
  closeSidebar: PropTypes.func,
  systemSession: PropTypes.func,
  match: PropTypes.instanceOf(Object),
  selectedTask: PropTypes.instanceOf(Object),
  cloudAccessDetails: PropTypes.instanceOf(Object),
  extendSession: PropTypes.func,
  toggleModal: PropTypes.func,
  createCancelQueueRequest: PropTypes.func,
  toggleToast: PropTypes.func,
  screenFromProject: PropTypes.string,
  getNiceDcvSdkDownloadLink: PropTypes.func,
};

export default CloudAccess;
